<template>
  <div class="mt-5">
    <v-data-table
      :headers="headers"
      :items="departments">
      <!-- <template #[`item.No`]="{ item }">
        {{ index + 1 }}
      </template> -->
      <template #[`item.createTime`]="{ item }">
        {{ item.createTime | formatDate }}
      </template>
      <template #[`item.activated`]="{ item }">
        <v-icon small
                :style="{ color: item.style.color }">
          mdi-toggle-switch
        </v-icon>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="getDepartment(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="openFormUpdateDepartment"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Department</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="department.name"
              label="Department name"
              outlined
              required />

            <v-checkbox
              v-model="department.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="updateDepartment">
            update
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUpdateDepartment = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DepartmentList',
  props: {
    departments: {
      type: Array,
    }
  },
  data() {
    return {
      openFormUpdateDepartment: false,
      editedIndex: -1,
      headers: [
        // {
        //   text: 'No.',
        //   align: 'center',
        //   sortable: false,
        //   value: 'No'
        // },
        {
          text: 'Department Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime'
        },
        {
          align: 'start',
          sortable: true,
          value: 'action'
        },
      ],
      department: {
        _id: '',
        name: '',
        activated: '',
      }
    };
  },

  // computed: {
  //   departmentList() {
  //     return this.departments.departments.map((item, index) => ({
  //       No: index + 1,
  //       ...item,
  //       style: {
  //         color: item.activated === true ? '#00C853' : '#C62828'
  //       }
  //     }));
  //   },
  // },

  methods: {
    async getDepartment(item) {
      this.editedIndex = this.departments.departments.indexOf(item);

      this.department = { ...item };
      this.openFormUpdateDepartment = true;
    },

    async updateDepartment() {
      await this.$store.dispatch('department/updateDepartment', this.department);
      this.openFormUpdateDepartment = false;
    }
  }
};
</script>

<style>
</style>
